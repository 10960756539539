<template>
  <div :class="getExpandStatus ? 'body-pd' : 'body-expand-pd'">
    <Navigation />
    <Header title="View Tickets" />
    <div class="horizontal-container">
      <div class="row" style="align-items: center;">
        <div class="newTicket">
          <button
            @click="$router.push({ name: 'new-ticket' })"
            class="purple-button"
          >
            New Ticket
          </button>
        </div>
        <div class="filter">
          <input
            type="text"
            placeholder="Search..."
            @keyup="search = $event.target.value"
          />
          <select v-model.lazy="ticketStatus">
            <option value="0">All</option>
            <option value="33729">New</option>
            <option value="33875">Pending</option>
            <option value="33732">In Progress</option>
            <option value="33730">Waiting for Customer</option>
          </select>
        </div>
      </div>
    </div>
    <div class="ticket-table">
      <table class="tickets">
        <thead>
          <th>#</th>
          <th>Title</th>
          <th>Date</th>
          <th>Priority</th>
          <th>Status</th>
        </thead>
        <tbody>
          <tr :key="ticket.Id" v-for="ticket in ticketList">
            <td>
              <router-link
                :to="{
                  name: 'ticket',
                  params: { TicketNumber: ticket.Id },
                }"
              >
                {{ ticket.TicketNumber }}
              </router-link>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'ticket',
                  params: { TicketNumber: ticket.Id },
                }"
              >
                {{ ticket.Title.substring(0, 35) }}
              </router-link>
            </td>
            <td>{{ ticket.OpenDate.substring(0, 10) }}</td>
            <td style="text-align: center">
              <span :class="ticket.Priority">{{ ticket.Priority }}</span>
            </td>
            <td style="text-align: center">
              <span :class="ticket.Status">{{ ticket.Status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Dashboard",
  components: {
    Navigation,
    Header,
    Footer,
  },
  data() {
    return {
      ticketList: "",
      ticketStatus: "",
      search: "",
    };
  },
  async mounted() {
    this.ticketList = this.$store.state.serviceDesk.tickets;
    if (!this.ticketList) {
      await this.$store.dispatch("serviceDesk/initializeTickets");
    }
    this.ticketList = this.$store.state.serviceDesk.tickets;
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getExpandStatus() {
      return this.$store.state.responsive.expand;
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push({ name: "login" });
    }
  },
  watch: {
    ticketStatus: function(value) {
      if (value != 0) {
        var ticketList = this.$store.state.serviceDesk.tickets;

        var newTicketList = ticketList.filter(function(ticket) {
          if (ticket.StatusId == value) return ticket;
        });

        this.ticketList = newTicketList;
      } else {
        this.ticketList = this.$store.state.serviceDesk.tickets;
      }
    },
    search: function(value) {
      var ticketList = this.$store.state.serviceDesk.tickets;
      if (value != "") {
        var newTicketList = ticketList.filter((d) =>
          value
            .split(" ")
            .every((v) => d.Details.toLowerCase().includes(v.toLowerCase()))
        );
        this.ticketList = newTicketList;
      } else {
        this.ticketList = ticketList;
      }
    },
  },
  methods: {
    getMatchingResults(value) {
      return this.ticketList.filter((d) =>
        value
          .split(" ")
          .every((v) => d.Details.toLowerCase().includes(v.toLowerCase()))
      );
    },
    formatNote() {
      return this.notes[0].NoteSummary.replace(/(\\r)*\\n/g, "line>");
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
a {
  color: inherit;
  text-decoration: none;
}

.horizontal-container {
  border-radius: 5px 5px 0 0;
}

.ticket-table {
  margin-top: -1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: white;
  border-radius: 0 0 5px 5px;
}

.tickets {
  width: 100%;
  word-wrap: break-word;
  color: #6e6b7b;
  text-indent: initial;
  border-spacing: 2px;
}

.tickets th {
  background-color: #f3f2f7;
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  border-top: 1px solid #ebe9f1;
  padding: 0.6rem 1rem;
  border-bottom: 2px solid #ebe9f1;
  font-weight: 500;
}

.tickets tbody td {
  padding: 0.7rem 1rem;
  border-top: 1px solid #ebe9f1;
  vertical-align: middle;
  display: table-cell;
  border-collapse: collapse;
  font-size: 14px;
}

.tickets tbody td:first-child {
  color: #7367f0;
  font-weight: 500;
}

.row {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.filter {
  margin-right: -16px;
  text-align: right;
  width: calc(100% - 135px);
  overflow: hidden;
}

.filter input {
  overflow: hidden;
  padding: 0.35rem 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  margin-right: 1rem;
  font-size: 14px;
  width: 250px;
}

.filter select {
  color: #6e6b7b;
  background-color: #fff;
  cursor: default;
  margin: 0em;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.35rem 0.2rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  font-family: "poppins";
}

input:focus {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  border-color: #7367f0 !important;
}

.newTicket {
  padding-left: 1rem;
  width: 151px;
  min-width: none;
}

@media only screen and (max-width: 850px) {
  .tickets tbody td {
    padding: 0.45rem 0.45rem;
  }

  .tickets th {
    padding: 0.45rem 0.45rem;
  }
}

@media only screen and (max-width: 550px) {
  .tickets tbody td {
    font-size: 12px;
  }

  .tickets th {
    font-size: 12px;
  }
}

@media only screen and (max-width: 740px) {
  .row .newTicket {
    width: 100%;
  }
  .row .filter {
    margin-top: 0.5em;
    width: 100%;
    display: flex;
  }
  .row .filter select {
    width: 180px;
  }
  .row .filter input {
    flex-grow: 1;
  }
}
.ticket-table {
  overflow-x: auto;
}
</style>
